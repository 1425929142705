import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Link } from 'gatsby-plugin-react-intl'
import Layout from '../layouts'
import Content, { HTMLContent } from '../components/Content'
import Img from 'gatsby-image'
// import DarkMode from '../components/DarkMode'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock'
import moment from 'moment'
import 'moment/locale/de'
// import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import '../components/addCal.css'
// import AddToCalendar from 'react-add-to-calendar'
import AddToCalendar from 'react-add-to-calendar-recurring'
import Share from '../components/Share'
import { Location } from '@reach/router'

export const EventPostTemplate = ({
  content,
  contentComponent,
  image,
  title,
  date,
  description,
  activ,
  helmet,
  location,
  isRecurring,
  repeatWeekly
}) => {
  const PostContent = contentComponent || Content

  const icon = { 'calendar-plus-o': 'left' }

  let isWeekly = repeatWeekly ? 'weekly' : ''

  const event = {
    title: title,
    description: description,
    location: location,
    startTime: moment(date).locale('de'),
    endTime: moment(date).locale('de'),
    recurring: {
      repeat: isWeekly, /* or weekly, daiLy, monthly, yearly */
      // byDay: 'TU,WE', /* SU,MO,TU,WE,TH,FR,SA */
      /* or byMonth: 1 (number) */
      interval: 1, /* Default is 1 */
      weekStart: 'SU', /* Week start default is Sunday */
      count: 10, /* Weekly for 10 occurrences */
    }
  }

  const items = [
    { outlook: 'Outlook' },
    { outlookcom: 'Outlook.com' },
    { apple: 'Apple Calendar' },
    { yahoo: 'Yahoo' },
    { google: 'Google' }
  ]

  return (
    <section className="section" >
      {helmet || ''}

      <div className='hero is-medium' style={{ position: 'relative' }}>
      {/* <div style={{ position: 'fixed', top: '55px', right: '20px' }}>
        <DarkMode />
      </div> */}
        <div className='hero-body' style={{ paddingBottom: '2rem' }}>
          <div className="content" id='blogPost'>
            <div className="columns is-multiline is-centered">
              {image ?
                <div className='column is-6'>
                  <div className='imageContainer' style={{ maxWidth: '350px', margin: '0 auto' }}>
                    <Img fluid={image.childImageSharp.fluid} />
                  </div>
                </div>
                : null}

              <div className="column is-6" style={{ margin: 'auto auto' }}>
                <h1 className="title is-size-2 has-text-weight-bold is-bold-light has-text-centered has-text-primary">
                  {title}
                </h1>
                <div className='has-text-centered'>
                  <span>
                    <FontAwesomeIcon icon={faCalendarAlt} size={14} className='has-text-primary' />{' '}
                    {isRecurring ? <span>Wöchentlich</span> : moment(date).local('de').format('dd DD.MM.YYYY')}
                  </span>
                  &nbsp;
                  <span className='location'>
                    <FontAwesomeIcon icon={faMapMarkerAlt} size={14} className='has-text-primary' />{' '}
                    {location}
                  </span>
                  &nbsp;
                  <span className='time'>
                    <FontAwesomeIcon icon={faClock} size={14} className='has-text-primary' />{' '}
                    {/*{time}*/}
                    {moment(date).local('de').format('HH:mm')}

                  </span>
                </div>
                <br />
                <PostContent content={content} />


                <div className='column is-12' style={{ margin: '2rem auto' }}>
                  <Location>
                    {({ location }) => {
                      const domain = 'https://www.caluba.at'
                      const path = location.pathname
                      const url = domain + path
                      return <React.Fragment>
                        <Share title={title} url={url} />
                      </React.Fragment>
                    }}
                  </Location>
                </div>

                <div className='column is-12' style={{ textAlign: 'center', margin: '4rem auto' }}>
                  <AddToCalendar
                    event={event}
                    buttonTemplate={icon}
                    buttonLabel="Hinzufügen"
                    listItems={items}


                  />
                </div>
              </div>

              <div className='column is-12 has-text-centered' style={{ marginBottom: '5rem' }}>
                <Link className='button is-outlined is-primary' to='/#events'>zurück
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

EventPostTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  // time: PropTypes.string,
  helmet: PropTypes.object
}

const EventPost = ({ data }) => {
  const { markdownRemark: post, navbarData, footerData, bannerData } = data

  return (
    <Layout navbarData={navbarData} bannerData={bannerData}>
      {/* <Layout navbarData={navbarData} bannerData={bannerData} footerData={footerData}> */}
      <EventPostTemplate
        image={post.frontmatter.image}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        date={post.frontmatter.date}
        helmet={
          <Helmet titleTemplate="%s | Event">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        location={post.frontmatter.location}
        title={post.frontmatter.title}
        active={post.frontmatter.active}
        isRecurring={post.frontmatter.isRecurring}
        repeatWeekly={post.frontmatter.repeatWeekly}
      />
    </Layout>
  )
}

EventPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default EventPost

export const pageQuery = graphql`
  query EventPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 350, quality: 92) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
        date
        title
        description
        active
        location
        isRecurring
        repeatWeekly
      }
    }
    ...LayoutFragment
  }
`
